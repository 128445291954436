import React from "react"
import styles from "./styles.module.css"
import  plus  from "../../images/plus.svg"
import  sub  from "../../images/minus.svg"
import { decimal } from '../../common/numFormat';
import Tooltip from "../Tooltip"

const SelectCountField = ({count, minCount, defaultCount, fieldName, fieldLabel, adjustCount, unitPrice, tooltipText, tooltipHeader, bottomGreenText, topGreenText}) => {
    fieldName = fieldName;
    const tooltipName = fieldName + 'Tooltip';
    return (
        <>
        <div className={["form-group", "fgroup", "S_inc_dec"].join(" ")} >
            {
                topGreenText && 
                <div>
                    <span className={styles.topGreenText}>{topGreenText}</span>
                </div>
            }
            <label htmlFor="name">{fieldLabel}
            {
                tooltipText &&
                <>
                <span className="qcircle" id={tooltipName} data-toggle="tooltip" data-placement="right">?</span>
                <Tooltip text={tooltipText} header={tooltipHeader} target={tooltipName}/>
                </>
            }
            </label>
            <div className={[styles.inpt_box]}>
                <div className={["row", "align-items-center"].join(" ")}>
                    <div className="col-6">
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" id="decrease" disabled={count===minCount} onClick={()=>{adjustCount(fieldName,false, minCount)}} className={["btn", "btn-text", "value-button", "plus-minus-btn"].join(" ")}><img src={sub} alt="" /></button>
                            <span className={[styles.number, "text-center"].join(" ")}>{count}</span>
                            <button type="button" id="increase" onClick={()=>{adjustCount(fieldName,true, minCount)}} className={["btn", "btn-text", "value-button", "plus-minus-btn"].join(" ")}><img src={plus} alt=" "/></button>
                        </div>
                    </div>
                        {
                            unitPrice !== null && unitPrice !== undefined && count>defaultCount &&
                            <span className={["text-right", "col-6", styles.price].join(" ")}>{"Rs. "} {decimal(unitPrice*(count-defaultCount))}</span> 
                        }
                </div>
                {
                    bottomGreenText && 
                    <span className={styles.freeLogin}>{bottomGreenText}</span>
                }
            </div>
        </div>
        </>

    )
}


export default SelectCountField

