import React from "react"
import styles from "./styles.module.css"
import  plus  from "../../images/plus.svg"
import  sub  from "../../images/minus.svg"
import { decimal } from '../../common/numFormat';
import Tooltip from "../Tooltip"

const SelectAddOns = ({numAddOns, minNumAddOns, type, fieldName, fieldLabel, showTypeText = true, adjustAddOns, singleAddOnPrice, tooltipText, tooltipHeader, showCloudLoginMsg=false, bottomGreenText, topGreenText}) => {
    fieldName = fieldName || ('num'+type+'AddOns');
    const tooltipName = fieldName + 'Tooltip';
    return (
        <>
        <div className={["form-group", "fgroup", "S_inc_dec"].join(" ")} >
            {
                topGreenText && 
                <div>
                    <span className={styles.topGreenText}>{topGreenText}</span>
                </div>
            }
            <label htmlFor="name">{fieldLabel || (showTypeText ? 'Number of ' + type + ' Add-ons' : 'Number of Add-ons')}
            {
                tooltipText &&
                <>
                <span className="qcircle" id={tooltipName} data-toggle="tooltip" data-placement="right">?</span>
                <Tooltip text={tooltipText} header={tooltipHeader} target={tooltipName}/>
                </>
            }
            </label>
            <div className={[styles.inpt_box]}>
                <div className={["row", "align-items-center"].join(" ")}>
                    <div className="col-6">
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" id="decrease" disabled={numAddOns===minNumAddOns} onClick={()=>{adjustAddOns(fieldName,false, minNumAddOns)}} className={["btn", "btn-text", "value-button", "plus-minus-btn"].join(" ")}><img src={sub} alt="" /></button>
                            <span className={[styles.number, "text-center"].join(" ")}>{numAddOns}</span>
                            <button type="button" id="increase" onClick={()=>{adjustAddOns(fieldName,true, minNumAddOns)}} className={["btn", "btn-text", "value-button", "plus-minus-btn"].join(" ")}><img src={plus} alt=" "/></button>
                        </div>
                    </div>
                        {
                            singleAddOnPrice !== null && singleAddOnPrice !== undefined && numAddOns>0 &&
                            <span className={["text-right", "col-6", styles.price].join(" ")}>{"Rs. "} {decimal(singleAddOnPrice*numAddOns)}</span> 
                        }
                </div>
                {
                    showCloudLoginMsg &&
                    <span className={styles.freeLogin}>1 FREE Cloud login already included</span>
                }
                {
                    bottomGreenText && 
                    <span className={styles.freeLogin}>{bottomGreenText}</span>
                }
            </div>
        </div>
        </>

    )
}


export default SelectAddOns

