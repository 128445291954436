import React from "react"
import styles from "./styles.module.css"
import rigtharrow from "../../images/right_down.svg"
import { decimal } from '../../common/numFormat';

class DropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            title: ""
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
    
    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                listOpen: false
            })
        }
      }

    toggleList = () => {
        if (!this.props.disabled) {
            this.setState(prevState => ({
                listOpen: !prevState.listOpen
            }))
        }
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    render() {
        const { showBorder=false, classes = "", data=[], disabled } = this.props; 
        return (
            <>
            {
                this.props.mprdropDown ?
                    (<div className={["dropdown", "mpr_dropdown","w-100", "align-self-center"].join(" ")} ref={this.setWrapperRef}>
                        <div className={["dd-header",this.state.listOpen ? styles.open : ""].join(" ")} onClick={() => this.toggleList()}>
                            
                            <div className={[ styles.mprbtnOption, "", "w-100", classes].join(" ")}>
                                    <span className={[styles.dropdownText, disabled ? styles.disabled : ''].join(" ")}> {this.props.title}</span>
                                    <span className={["float-right ml-2", this.state.listOpen ? styles.open : ""].join(" ")}>
                                        <i className={[styles.select_arrow,"fa fa-angle-down", this.state.listOpen ? styles.open : ""].join(" ")} aria-hidden="true"></i>
                                    </span>
                            </div>
                            
                        </div>
                        {this.state.listOpen && <ul className={["dropdown-menu", this.state.listOpen ? "show" : ""].join(" ")}>
                        {data.map((item,index) => (
                            <li className={["dropdown-item", data.length > 0 ? (data[index].disabled ? styles.disabled : "") : ""].join(" ")} key={index} onClick={()=>{this.props.callBack(item); this.setState({listOpen: false})}} >{item.text || item}</li>
                        ))}
                        </ul>}
                    </div>)
                :
                    (<div className={["dropdown", "mpr_dropdown"].join(" ")} ref={this.setWrapperRef}>
                        <div className={["dd-header",this.state.listOpen ? styles.open : ""].join(" ")} onClick={() => this.toggleList()}>
                            
                            <div className={[styles.btnOption, "btn", "w-100", "m-0", showBorder && styles.borderBtm].join(" ")} style={{cursor: disabled ? 'default' : 'pointer'}}>
                                <div className="row align-items-center">
                                    <span className={["col-8"].join(" ")}>
                                        {this.props.title}
                                        {
                                            this.props.price && !disabled &&
                                            <img className={[styles.select_arrow, this.state.listOpen ? styles.open : ""].join(" ")} src={rigtharrow} alt="Dropdown Arrow"/>
                                        }
                                    </span>
                                    {
                                        !this.props.price ?
                                        (
                                            !disabled &&
                                            <span className={["col-4", "text-right", this.state.listOpen ? styles.open : ""].join(" ")}><img className={[styles.select_arrow, this.state.listOpen ? styles.open : ""].join(" ")} src={rigtharrow} alt="Dropdown Arrow"/></span>
                                        )
                                        :
                                        <span className={["col-4" , styles.price, "text-right"].join(" ")}>Rs. {decimal(this.props.price)}</span>
                                    }
                                    {/* {this.props.price ? <span className={["col-3" , styles.price, "text-right"].join(" ")}>Rs. {decimal(this.props.price)}</span> : <span className={["col-3" , "p-0" , "d-inline-block"].join(" ")}></span>} */}
                                    {/* <span className={["col-2", "text-right", this.state.listOpen ? styles.open : ""].join(" ")}><img className={[styles.select_arrow, this.state.listOpen ? styles.open : ""].join(" ")} src={rigtharrow}/></span> */}
                                </div>
                            </div>
                            
                        </div>
                        {this.state.listOpen && <ul className={["dropdown-menu", this.state.listOpen ? "show" : ""].join(" ")}>
                        {data.map((item,index) => (
                            <li className={["dropdown-item", data.length > 0 ? (data[index].disabled ? styles.disabled : "") : ""].join(" ")} key={item.id} onClick={()=>{this.props.callBack(item); this.setState({listOpen: false})}} >{item.text}</li>
                            ))}
                        </ul>}
                    </div>)
            }
            </>

        )
    }
}


export default DropDown

