import React from "react"
import styles from "./styles.module.css"
import { decimal } from '../../common/numFormat';
import Tooltip from "../Tooltip"
import FormField from "../formField";

const SelectAdditionalService = ({services, toggleService, isUpgrade}) => {
    return (
        <>
        <div className={["form-group", "fgroup", "S_inc_dec"].join(" ")} >
            <label htmlFor="name" className={styles.additionalPackLabel}>{'Additional Packs'}
                {/* <span className="qcircle" id={tooltipName} data-toggle="tooltip" data-placement="right">?</span> */}
                {/* {
                    tooltipText &&
                    <Tooltip text={tooltipText} header={tooltipHeader} target={tooltipName}/>
                } */}
            </label>
            <div className={[styles.inpt_box,'d-flex flex-column align-items-stretch'].join(" ")}>
                {
                    services.map((service,index) => (
                        <ServiceItem service={service} index={index} toggleService={toggleService} key={index} isUpgrade={isUpgrade}/>
                    ))
                }
            </div>
        </div>
        </>

    )
}

const ServiceItem = ({service, index, toggleService, isUpgrade}) => {
    if (service.Hidden) {
        return <></>;
    }
    const price = service.Price;
    const tooltipName = 'additionalPack-'+index;
    const priceTooltipName = 'additionalPackPrice-'+index;
    const priceTooltipText = service.IsUpgradeAfterHalf ? [`Pay Rs. ${decimal(price)} to enable this pack until your current license expires.`,`Price is Rs. ${decimal(price*2)} per year thereafter.`] : null
    return (
        <div className={["align-items-center justify-content-between d-flex flex-row", styles.serviceContainer].join(" ")}>
            <div className="flex-grow">
                <div className={["custom-control", "custom-checkbox"].join(" ")}>    
                    <input className={["custom-input", "mr-2", service.Disabled && "disabled"].join(" ")} id={'additionalServiceCheckbox-'+service.ServiceType} disabled={service.Disabled} name="IsBillingSame" type="checkbox" checked={service.Active} onChange={(event)=>toggleService(service.ServiceType,event)} />
                    <label className={styles.name} htmlFor={'additionalServiceCheckbox-'+service.ServiceType}>{service.Name}
                    {
                        service.TooltipText &&
                        <>
                        <span className="qcircle" id={tooltipName} data-toggle="tooltip" data-placement="right">?</span>
                        <Tooltip text={service.TooltipText} header={service.TooltipHeader} target={tooltipName}/>
                        </>
                    }
                    </label>
                </div>
                {/* <FormField fieldName={'xirr'} label="Invoice in Company Name?" sectionName={null} sectionValue={state} handleInputChange={()=>{}} sizeOverride={6} disabled={true} inputType={'checkbox'}/>
                <span className={[styles.name, "text-center"].join(" ")}>{name}</span> */}
            </div>
                {
                    price !== null && price !== undefined && service.Active && !(!service.Disabled && price == 0) ?
                    <span className={["text-right", styles.price, price == 0 && styles.freePrice].join(" ")}>
                        {
                            price == 0 ? 'FREE' : `Rs. ${decimal(price)}`
                        }
                        {
                            service.IsUpgradeAfterHalf && isUpgrade &&
                            <>
                                <span className="qcircle" id={priceTooltipName} data-toggle="tooltip" data-placement="right">?</span>
                                <Tooltip text={priceTooltipText} header={null} target={priceTooltipName}/>
                            </>
                        }
                    </span> 
                    :
                    <span className={["text-right", styles.price, styles.emptyPrice].join(" ")}>
                    </span>
                }
        </div>
    )
}


export default SelectAdditionalService

