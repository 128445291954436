import { processResponse, createUrlQueryParams } from "../common/gFunctions";
import { CUSTOMERORDERPLACEMENT, CUSTOMERPLAN, ORDERLIST, EDITCUSTOMERDETAILS, SHAREREFERRALCODE, VIEWGSTINVOICE, VIEWCLOUDADDONS, DELETECLOUDADDONS, CREATECLOUDADDONS, CUSTOMERPROFILEDETAILS, CUSTOMERREFERRALDETAILS, EDITCORRESPONDENCEEMAILS, SUBSCRIPTIONPREFS, PLANRECOMMENDATIONDETAILS, PLANRECOMMENDATIONREFRESH, PLANRECOMMENDATIONREQUESTPROFORMA, PLANRECOMMENDATIONUPDATEISWIDGETOPENED, PLANRECOMMENDATIONGETISWIDGETOPENED } from "./API";

export const postMethod = (token, url, query, body) => {
    url = createUrlQueryParams(url,query);
    console.log(url,'url');
    console.log(body,'body');
    body = body ? JSON.stringify(body) : null;
    return fetch(url,
        { 
            method: 'POST',
            headers: 
            {
                'Authorization': 'bearer '+token,
                'Content-Type': 'application/json'
            },
            body
        }
    ).then(processResponse);
}

export const getMethod = (token, url, query={}) => {
    url = createUrlQueryParams(url,query);
    console.log(url);
    return fetch(url,
    {
        headers: new Headers({
            'Authorization': 'bearer '+token,
        }),
    }).then(processResponse);
}

export const viewOrderPlacementDetails = (token) => {
    var url = CUSTOMERORDERPLACEMENT;
    return fetch(url,{
        method: 'GET',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        }
    }).then(processResponse);
}

export const viewPlan = (token) => {
    var url = CUSTOMERPLAN;
    return fetch(url,{
        method: 'GET',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        }
    }).then(processResponse);
}

export const viewPlanRecommendationDetails = (token) => {
    var url = PLANRECOMMENDATIONDETAILS;
    return fetch(url,{
        method: 'GET',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        }
    }).then(processResponse);
}

export const refreshPlanRecommendationDetails = (token) => {
    var url = PLANRECOMMENDATIONREFRESH;
    return fetch(url,{
        method: 'POST',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        }
    }).then(processResponse);
}

export const requestPlanRecommendationProforma = (token, body) => {
    var url = PLANRECOMMENDATIONREQUESTPROFORMA;
    return postMethod(token, url, {}, body);
}

export const setIsPlanRecommendationWidgetOpened = (token) => {
    var url = PLANRECOMMENDATIONUPDATEISWIDGETOPENED;
    return postMethod(token, url, {isWidgetOpened: true}, {});
}

export const getIsPlanRecommendationWidgetOpened = (token) => {
    var url = PLANRECOMMENDATIONGETISWIDGETOPENED;
    return getMethod(token, url, {});
}

export const orderList = (token) => {
    var url = ORDERLIST;
    return fetch(createUrlQueryParams(ORDERLIST,{perPage:50}),{
        method: 'GET',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        }
    }).then(processResponse);
}

export const editCustomerDetails = (params,token) => {
    return fetch(EDITCUSTOMERDETAILS,
     { 
        method: 'POST',
        headers: 
        {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    }).then(processResponse)
}

export const shareReferralCode = (token,sendToEmail) => {
    const url = createUrlQueryParams(SHAREREFERRALCODE,{sendToEmail});
    return fetch(url,
     { 
        method: 'POST',
        headers: 
        {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json'
        },
    }).then(processResponse)
}

export const viewGstInvoice = (token,invoiceNum) => {
    const url = createUrlQueryParams(VIEWGSTINVOICE,{invoiceNum});
    return fetch(url,
     { 
        method: 'GET',
        headers: 
        {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json'
        },
    }).then(processResponse)
}

export const viewCloudAddOns = (token) => {
    return getMethod(token,VIEWCLOUDADDONS)
}

export const deleteCloudAddOns = (token, addOnEmailAddr) => {
    return postMethod(token,DELETECLOUDADDONS,{addOnEmailAddr: encodeURIComponent(addOnEmailAddr)})
}

export const createCloudAddOns = (token, addOnEmailAddr) => {
    return postMethod(token,CREATECLOUDADDONS,{addOnEmailAddr: encodeURIComponent(addOnEmailAddr)})
}

export const viewCustomerProfileDetails = (token) => {
    return getMethod(token,CUSTOMERPROFILEDETAILS)
}

export const viewCustomerReferralDetails = (token) => {
    return getMethod(token,CUSTOMERREFERRALDETAILS)
}

export const editCorrespondenceEmails = (token, body) => {
    return postMethod(token,EDITCORRESPONDENCEEMAILS,{},body);
}

export const getSubscriptionPrefs = (query) => {
    var url = createUrlQueryParams(SUBSCRIPTIONPREFS,query);
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(processResponse)
}

export const updateSubscriptionPrefs = (query, body) => {
    var url = createUrlQueryParams(SUBSCRIPTIONPREFS,query);
    body = body ? JSON.stringify(body) : null;
    return fetch(url,
        { 
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json'
            },
            body
        }
    ).then(processResponse);
}