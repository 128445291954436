import React from "react"

import styles from "./styles.module.css";
import {isMobileOnly,isIOS} from 'react-device-detect';
import PricingCard from "../pricingCard";
import { PricingCalculator } from "../pricingCalculator";
import { StaticQuery, navigate } from "gatsby";
import { Modal } from "reactstrap";

const query = graphql`
query pricingCardModalQuery{
  allContentJson {
    edges {
      node {
        investorsFeaturesContent {
            banner {
              title
              subtitle
            }
            products {
              tier
              title
              for
              price
              currency
              perText
              gstText
              featuresTitle
              features {
                order
                title
                tooltip
              }
            }
        }
      }
    }
  }
}
`;

const PricingCardModal = ({isOpen, onCloseModal, isWealth}) => (
    <StaticQuery
      query={query}
      render={queryData => {
        console.log(queryData, 'PricingCardModal');

        const data = queryData.allContentJson.edges[0].node.investorsFeaturesContent;

        if (!data) return <></>;

        var isMobileFinal = false, isIOSFinal = false;

        if (typeof window !== "undefined") {
            isMobileFinal = isMobileOnly;
            isIOSFinal = isIOS;
        }
        
        return (
            <Modal isOpen={isOpen} modalClassName={styles.modalOuter} className={styles.modalDialog} contentClassName={styles.modalContainer}>
                <div className={[styles.closeBtnMobile].join(' ')} onClick={onCloseModal}><i className={"material-icons"}>close</i></div>
                <div className={[styles.closeBtn].join(' ')} onClick={onCloseModal}><i className={"material-icons"}>close</i></div>
                <div className={[styles.pricingCardsContainer].join(" ")}>
                    {
                        data.products.map((product, index) => {
                            if (product.price != 0) {
                                return <PricingCard product={product} isCollapsedInitial={isMobileFinal && index > 0} key={index} hideCTA={true} isWealth={isWealth}/>
                            } else {
                                return <></>;
                            }
                        })
                    }
                </div>
            </Modal>
        );
      }}
    />
)

// const PricingCardModal = () => (
//     <StaticQuery
//       query={query}
//       render={data => {
//         // const {isOpen } = props;

//         const { isPricingCalculatorOpen } = this.state;

//         var isMobileFinal = false, isIOSFinal = false;

//         if (typeof window !== "undefined") {
//             isMobileFinal = isMobileOnly;
//             isIOSFinal = isIOS;
//         }

//         console.log(data, 'dataPricing')

//         return (
//             <></>
//             // <Modal isOpen={isOpen} modalClassName={styles.modalOuter} className={styles.modal} contentClassName={styles.modalContainer}>
//             //     <div className={[styles.pricingCardsContainer].join(" ")}>
//             //         {
//             //             data.products.map((product, index) => (
//             //                 <PricingCard product={product} isCollapsedInitial={isMobileFinal && index > 0} onClickCTA={this.onClickCTA} key={index}/>
//             //             ))
//             //         }
//             //     </div>
//             // </Modal>
//         );
//       }}
//     />
// )

export default PricingCardModal
